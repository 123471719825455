@import 'primeicons/primeicons.css';
@import '../node_modules/primeflex/primeflex.css';
// import Quill styles in styles.scss
@import '../node_modules/quill/dist/quill.core.css';
@import '../node_modules/quill/dist/quill.snow.css';

@import 'assets/2care4-theme/theme.scss';

html {
  font-size: 0.85rem;
  background-color: #f1f1f1;

  body {
    margin: 0;
    overflow-y: scroll;

    .container {
      min-height: 90vh;
      max-width: 100vw;
    }
  }
}