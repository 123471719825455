.p-progressbar {
  border: $progressBarBorder;
  height: $progressBarHeight;
  background: $progressBarBg;
  border-radius: $borderRadius;

  .p-progressbar-value {
    border: 0 none;
    margin: 0;
  }

  .p-progressbar-label {
    color: $progressBarValueTextColor;
    line-height: $progressBarHeight;
  }
}

/** Conditional progressbar colors */
.primary-color .p-progressbar-value {
  background: var(--primary-color);
}

.secondary-color .p-progressbar-value {
  background: var(--secondary-color);
}

.danger-color .p-progressbar-value {
  background: var(--danger-color);
}
